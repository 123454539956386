import React from 'react'
import { Link } from 'react-router-dom'


export default function Navbar(props) {
    return (
        <div>
            {/* <nav className={`navbar navbar-expand-lg navbar-${props.greenMode}} bg-${props.greenMode}`}> */}
            <nav className={`navbar navbar-expand-lg navbar-${props.mode}} bg-${props.mode}`}>
                <div className="container-fluid">
                    <Link className={`navbar-brand  text-${props.mode === 'light' ? 'dark' : 'light'}`} to="/">{props.title} </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className={`nav-link   text-${props.mode === 'light' ? 'dark' : 'light'}`} aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link  text-${props.mode === 'light' ? 'dark' : 'light'}`} to="/about">{props.AboutText}</Link>
                            </li>
                        </ul>
                        {/* <form className="d-flex">
                            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                            <button className="btn btn-outline-success" type="submit">Search</button>
                        </form> */}
                    </div>
                    {/* <div className={`form-check form-switch text-${props.greenMode === 'light' ? 'dark' : 'light'} `}>
                        <input  onClick={props.toggleGreenMode} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                        <label className="form-check-label mx-2" htmlFor="flexSwitchCheck">{props.greenModeText}</label>
                    </div> */}
                    <div className={`form-check form-switch text-${props.mode === 'light' ? 'dark' : 'light'} `}>
                        <input onClick={props.toggleMode} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{props.modeText}</label>
                    </div>
                </div>
            </nav>
        </div>
    )
}
